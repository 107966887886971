<template>
  <div class="certificates-container">
    <h2 class="mb-4">Mis Certificados</h2>

    <div class="table-responsive">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th scope="col" class="text-primary">
              Nombres Curso <i class="bi bi-question-circle-fill ms-1"></i>
            </th>
            <th scope="col" class="text-primary text-center">
              Certificado del Modulo <i class="bi bi-question-circle-fill ms-1"></i>
            </th>
            <th scope="col" class="text-primary text-center">
              Certificado del Curso <i class="bi bi-question-circle-fill ms-1"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(c, index) in cursos_matriculados" :key="c.id">
            <td>
              <div class="d-flex align-items-center">
                <div
                  class="certificate-icon me-3"
                  :class="getCertificateIconClass(index)"
                ></div>
                <p>{{ c.curso.nombre }}</p>
              </div>
            </td>
            <td class="text-center">
              <button @click="irModulos(c.curso.id)" class="btn btn-primary">
                Certificados por Módulo
              </button>
            </td>
            <td class="text-center">
              <span
                v-if="
                  certificados[c.curso.id] &&
                  certificados[c.curso.id].codigo_certificado
                "
              >
                <button
                  @click="descargarCertificado(c.curso.id)"
                  class="btn btn-download"
                >
                  <i class="bi bi-download"></i>
                </button>
              </span>
              <span v-else>Sin Certificado</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Preloader :visible="cargando" />
  </div>
</template>

<script>
import MatriculaService from "@/services/MatriculaService";
import CertificadoService from "@/services/CertificadoService";
import Preloader from "../components/Preloader.vue";

export default {
  name: "CertificadosComponente",
  components: {
    Preloader,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      cursos_matriculados: [],
      cargando: false,
      certificados: {},
    };
  },
  created() {
    if (this.user) {
      this.getCursosMatriculadosPorUsuario(this.user.id);
    }
  },
  methods: {
    async getCursosMatriculadosPorUsuario(id) {
      try {
        this.cargando = true;
        const responseMatriculas = await MatriculaService.getCursosMatriculadosPorUsuario(id);
        const cursos = responseMatriculas.data.data;

        for (const curso of cursos) {
          await this.obtenerCertificado(curso.curso.id);
        }

        this.cursos_matriculados = cursos;
      } catch (error) {
        console.error("Error al obtener cursos matriculados:", error);
      } finally {
        this.cargando = false;
      }
    },

    async obtenerCertificado(id_curso) {
      try {
        const responseCertificado = await CertificadoService.getCertificadoporCursodeUsuario(
          id_curso,
          this.user.id
        );
        this.certificados[id_curso] = responseCertificado.data.data || null;
      } catch (error) {
        console.error("Error al obtener el certificado:", error);
        this.certificados[id_curso] = null;
      }
    },

    descargarCertificado(id_curso) {
      const certificado = this.certificados[id_curso];

      if (certificado) {
        const url = `https://certificado.micampusenlinea.com/certificadoacc.php?id=${certificado.id}`;
        window.open(url, "_blank");
      } else {
        console.warn("Certificado no disponible para descargar.");
        this.$swal({
          icon: "error",
          title: "Certificado no disponible",
          text: "Este curso no tiene un certificado disponible para descargar.",
        });
      }
    },

    irModulos(id_curso) {
      this.$router.push({
        name: "CertificadoporModulo",
        params: {
          id_curso: id_curso,
        },
      });
    },

    getCertificateIconClass(index) {
      return index % 2 === 0 ? "bg-primary" : "bg-warning";
    },
  },
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.0/font/bootstrap-icons.css");

.certificates-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.table th {
  font-weight: bold;
  color: #6c757d;
}

.certificate-icon {
  width: 36px;
  height: 36px;
  border-radius: 8px;
}

.btn-download {
  padding: 6px 10px;
  border-radius: 6px;
}

.bg-primary {
  background-color: #ff9800 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.text-primary {
  color: #6C757D !important;
}

.bi-question-circle-fill {
  font-size: 0.8rem;
}

.bi-download {
  font-size: 1.2rem;
}
</style>

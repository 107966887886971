<template>
  <div class="container-fluid px-4 py-3 notification-panel">
    <div class="row align-items-center mb-3">
      <div class="col-12 col-md-6">
      </div>
    </div>

    <div v-if="cargandoNotificaciones" class="text-center py-4">
      <div class="spinner-grow text-primary" role="status">
        <span class="visually-hidden">Cargando...</span>
      </div>
    </div>

    <div
      v-else-if="notificacionesFiltradas.length === 0"
      class="text-center py-4 empty-state"
    >
      <i class="bi bi-inbox fs-1 text-muted"></i>
      <p class="mt-2 text-muted">No hay notificaciones</p>
    </div>

    <div v-else class="notification-list">
      <div
        v-for="notificacion in notificacionesFiltradas"
        :key="notificacion.id"
        class="notification-card"
        :class="{
          'notification-unread': notificacion.estado === 1,
          'notification-read': notificacion.estado === 0,
        }"
        @click="abrirModal(notificacion)"
      >
        <div class="notification-content">
          <div class="notification-header">
            <span class="notification-type text-muted small">{{
              notificacion.tipo
            }}</span>
            <span class="notification-date text-muted small">{{
              formatearFecha(notificacion.fecha_envio)
            }}</span>
          </div>
          <p
            class="notification-message"
            v-html="truncarMensaje(notificacion.mensaje)"
          ></p>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="detallesNotificacionModal"
      tabindex="-1"
      aria-labelledby="detallesNotificacionLabel"
      aria-hidden="true"
      ref="detallesModal"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content shadow-lg">
          <div class="modal-header border-bottom-0">
            <h5 class="modal-title" id="detallesNotificacionLabel">
              Detalles de Notificación
            </h5>
            <button
              type="button"
              class="btn-close"
              @click="cerrarModal"
              aria-label="Cerrar"
            ></button>
          </div>
          <div class="modal-body">
            <h6
              class="text-dark mb-3"
              v-html="notificacionDetalle.mensaje"
            ></h6>
            <div class="notification-details">
              <p class="text-muted mb-2">
                <strong>Tipo:</strong> {{ notificacionDetalle.tipo }}
              </p>
              <p class="text-muted">
                <strong>Fecha de envío:</strong>
                {{ formatearFecha(notificacionDetalle.fecha_envio) }}
              </p>
            </div>
          </div>
          <div class="modal-footer border-top-0">
            <button
              type="button"
              class="btn btn-secondary"
              @click="cerrarModal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.notification-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.notification-card {
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.notification-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.notification-unread {
  background-color: #e6f2ff;
  border-left: 4px solid #0d6efd;
}

.notification-read {
  background-color: white;
  border-left: 4px solid transparent;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.notification-message {
  margin-bottom: 0;
  color: #212529;
}

.modal-content {
  border-radius: 12px;
  border: none;
}

.empty-state {
  background-color: white;
  border-radius: 12px;
  padding: 2rem;
}

@media (max-width: 768px) {
  .notification-panel {
    padding: 1rem;
  }

  .notification-card {
    padding: 0.75rem;
  }
}
</style>

<script>
import NotificacionesService from "@/services/NotificacionesService";
import { Modal } from "bootstrap";

export default {
  data() {
    return {
      notificaciones: [],
      cargandoNotificaciones: false,
      usuario: JSON.parse(localStorage.getItem("user")) || {},
      busqueda: "",
      notificacionSeleccionada: null,
      notificacionDetalle: {},
      modalInstance: null,
    };
  },
  computed: {
    notificacionesFiltradas() {
      let filtradas = this.notificaciones.filter((notificacion) => {
        const coincideBusqueda =
          !this.busqueda ||
          notificacion.mensaje
            .toLowerCase()
            .includes(this.busqueda.toLowerCase()) ||
          notificacion.tipo.toLowerCase().includes(this.busqueda.toLowerCase());
        return coincideBusqueda;
      });

      filtradas.sort((a, b) => {
        if (a.estado === 1 && b.estado !== 1) return -1;
        if (b.estado === 1 && a.estado !== 1) return 1;
        return new Date(b.fecha_envio) - new Date(a.fecha_envio); // Ordenar por fecha descendente
      });

      return filtradas;
    },
  },
  mounted() {
    this.modalInstance = new Modal(this.$refs.detallesModal, {
      backdrop: "static",
      keyboard: false,
    });
  },
  created() {
    this.obtenerNotificaciones();
  },
  methods: {
    async obtenerNotificaciones() {
      const usuarioId = this.usuario.id;
      if (!usuarioId) {
        console.warn("ID del usuario no encontrado en localStorage.");
        return;
      }
      try {
        this.cargandoNotificaciones = true;
        const response =
          await NotificacionesService.getMisnotificacines(usuarioId);

        // Asegurarse de que las notificaciones tengan la propiedad 'fecha_envio' formateada
        this.notificaciones = response.data.data.map((notificacion) => ({
          ...notificacion,
          fecha_envio: this.formatearFechaBackend(notificacion.fecha_envio),
        }));
      } catch (error) {
        console.error("Error al cargar notificaciones:", error);
      } finally {
        this.cargandoNotificaciones = false;
      }
    },

    formatearFechaBackend(fechaString) {
      const fecha = new Date(fechaString);
      return fecha.toISOString(); // Guardar en ISO para formatear en el frontend
    },

    formatearFecha(fechaISO) {
      const fecha = new Date(fechaISO);
      const ahora = new Date();

      if (fecha.toDateString() === ahora.toDateString()) {
        return fecha.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
      }

      return fecha.toLocaleDateString([], {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
    },
    truncarMensaje(mensaje) {
      const textoLimpio = this.stripHtml(mensaje);
      if (textoLimpio.length > 30) {
        return `${textoLimpio.substring(0, 30)}...`;
      }
      return textoLimpio;
    },

    stripHtml(html) {
      const div = document.createElement("div");
      div.innerHTML = html;
      return div.textContent || div.innerText || "";
    },

    abrirModal(notificacion) {
      this.notificacionSeleccionada = notificacion.id;
      this.notificacionDetalle = notificacion;
      if (notificacion.estado === 1) {
        this.desactivarNotificacion(notificacion.id);
      }
      this.modalInstance.show();
    },

    cerrarModal() {
      this.modalInstance.hide();
      this.notificacionSeleccionada = null;
      this.notificacionDetalle = {};
    },

    async desactivarNotificacion(notificacionId) {
      try {
        await NotificacionesService.desactivar(notificacionId);
        const notificacion = this.notificaciones.find(
          (n) => n.id === notificacionId
        );
        if (notificacion) notificacion.estado = 0;
      } catch (error) {
        console.error("Error al desactivar notificación:", error);
      }
    },
  },
};
</script>

<template>
  <nav
    class="navbar navbar-main navbar-expand-lg mxx-4"
    :class="isRTL ? 'top-0 position-sticky z-index-sticky' : ''"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="container-fluid text-dark">
      <div
        class="collapse navbar-collapse mt-2"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div class="d-flex align-items-center ocultar" style="flex-grow: 1">
          <div
            class="input-group pe-md-3"
            style="max-width: 50%; position: relative"
          >
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Busca tus Cursos, Modulos y Lecciones..."
              v-model="query"
              @input="handleInput"
            />
            <!-- Dropdown de sugerencias -->
            <div
              v-if="
                mostrarListaSugerencias &&
                sugerenciasFiltradas.length &&
                query.length > 0
              "
              class="suggestions-list"
            >
              <ul>
                <li
                  v-for="(sugerencia, index) in sugerenciasFiltradas"
                  :key="index"
                  @click="irASugerencia(sugerencia)"
                >
                  <strong>{{ sugerencia.nombre }}</strong>
                  <span>({{ sugerencia.tipo }})</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ul class="navbar-nav justify-content-end align-items-center">
          <li
            class="nav-item d-flex align-items-center notification-button me-2"
          >
            <a
              href="#"
              @click="$router.push({ name: 'Calendario' })"
              class="d-flex align-items-center"
            >
              <i class="far fa-calendar me-1"></i>
              <span class="d-md-inline-block" style="font-size: 12px;">
                {{ currentDate }}
              </span>
            </a>
          </li>

          <!-- Notificaciones Dropdown -->
          <li
            class="nav-item dropdown d-flex align-items-center me-3 position-absolute-center"
          >
            <a
              href="#"
              class="p-0 nav-link"
              :class="[showMenu ? 'show' : '']"
              id="dropdownMenuButton"
              @click.stop="toggleMenu"
            >
              <i class="cursor-pointer fa fa-bell"></i>
              <span
                v-if="hayNotificaciones"
                class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle"
              ></span>
            </a>
            <ul
              ref="notificationMenu"
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4 notifications-menu"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li v-if="cargandoNotificaciones" class="text-center">
                <span>Cargando notificaciones...</span>
              </li>
              <li
                v-else
                v-for="notificacion in notificaciones"
                :key="notificacion.id"
                class="mb-2"
              >
                <div class="dropdown-item-custom">
                  <div class="py-1 d-flex">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <i class="fa fa-dot-circle" style="color: red"></i>
                        {{ notificacion.tipo }}
                      </h6>
                      <p class="mb-0 text-xs" v-html="notificacion.mensaje"></p>                      <small class="text-muted">{{
                        notificacion.fecha_envio
                      }}</small>
                    </div>
                  </div>
                  <a
                    href="#"
                    class="btn btn-outline-secondary btn-sm"
                    @click.prevent="irANotificacion(notificacion)"
                  >
                    Marcar como Leído
                  </a>
                </div>
              </li>
              <li
                v-if="!cargandoNotificaciones && notificaciones.length === 0"
                class="text-center"
              >
                <span>No hay notificaciones</span>
              </li>
            </ul>
          </li>

          <!-- Perfil de Usuario -->
          <li
            class="nav-item d-flex align-items-center me-3"
            v-if="user?.nombres"
          >
            <div class="d-flex flex-column align-items-start">
              <a class="text-dark">{{ primerNombre }}</a>
              <a
                href="#"
                class="text-secondary"
                @click="$router.push({ name: 'PerfilUsuario' })"
                style="font-size: 12px"
              >
                Ver Perfil
              </a>
            </div>
          </li>

          <!-- Icono de Foto de Perfil -->
          <li class="nav-item">
            <a href="#" class="nav-link text-body p-0">
              <div class="bg-warning rounded-circle bg-warning-custom">
                <img
                  v-if="user.foto_perfil"
                  :src="fotoPerfilUrl"
                  alt="Foto de perfil"
                  class="perfil-foto-icono"
                />
                <i v-else class="fas fa-image text-white"></i>
              </div>
            </a>
          </li>

          <!-- Sidebar Minimize Button -->
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center me-4">
            <a
              href="#"
              @click="minimizeSidebar"
              class="p-0 nav-link iconNavbarSidenav"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>


<script>
import { mapState } from "vuex";
import notificacionesService from "@/services/NotificacionesService";
import UsuarioService from "../../services/UsuarioService";
import ModuloService from "../../services/ModuloService";
import LeccionService from "../../services/LeccionService";

export default {
  data() {
    return {
      showMenu: false,
      notificaciones: [],
      cargandoNotificaciones: false,
      user: JSON.parse(localStorage.getItem("user")) || {},
      query: "",
      sugerencias: [],
      mostrarListaSugerencias: false,
      id_curso: this.$route.params.id_curso,
      id_modulo: this.$route.params.id_modulo,
      id_leccion: this.$route.params.id_leccion,
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  computed: {
    primerNombre() {
    return this.user.nombres.split(' ')[0]; 
  },
    ...mapState(["isRTL"]),
    fotoPerfilUrl() {
      return this.user.foto_perfil
        ? `${process.env.VUE_APP_API_URL}/storage/${this.user.foto_perfil}`
        : null;
    },

    ...mapState(["isRTL"]),
    currentDate() {
      const now = new Date();
      return now.toLocaleString("es-ES", {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    hayNotificaciones() {
      return this.notificaciones.some(
        (notificacion) => notificacion.estado === 1
      );
    },
    sugerenciasFiltradas() {
      if (!this.query) return [];
      const queryLower = this.query.toLowerCase();
      return this.sugerencias.filter((item) =>
        item.nombre.toLowerCase().includes(queryLower)
      );
    },
  },
  created() {
    this.obtenerNotificaciones();
  },
  methods: {
    async handleInput() {
      if (this.query.length > 0) {
        await this.obtenerSugerencias();
        this.mostrarListaSugerencias = true;
      } else {
        this.mostrarListaSugerencias = false;
        this.sugerencias = [];
      }
    },
    ocultarSugerencias() {
      setTimeout(() => {
        this.mostrarListaSugerencias = false;
      }, 100);
    },
    async obtenerSugerencias() {
      const usuarioId = this.user.id;
      try {
        const response = await UsuarioService.getbuscador(usuarioId);
        if (Array.isArray(response.data.busqueda)) {
          this.sugerencias = response.data.busqueda;
        } else {
          this.sugerencias = [];
        }
      } catch (error) {
        console.error("Error al obtener sugerencias:", error);
        this.sugerencias = [];
      }
    },
    async irASugerencia(sugerencia) {
      const { tipo, id } = sugerencia;
      try {
        if (tipo === "curso") {
          this.irACurso(id);
        } else if (tipo === "modulo") {
          const id_curso = await this.obtenerCursoDeModulo(id);
          if (id_curso) {
            this.irAModulo(id_curso, id);
          } else {
            console.error("No se pudo obtener el id_curso para el módulo:", id);
          }
        } else if (tipo === "leccion") {
          const { id_curso, id_modulo } =
            await this.obtenerCursoYModuloDeLeccion(id);
          if (id_curso && id_modulo) {
            this.irALeccion(id_curso, id_modulo, id);
          } else {
            console.error(
              "No se pudo obtener id_curso y id_modulo para la lección:",
              id
            );
          }
        } else {
          console.warn("Tipo de sugerencia desconocido:", tipo);
        }
      } catch (error) {
        console.error("Error al redirigir:", error);
      } finally {
        this.mostrarListaSugerencias = false;
      }
    },
    irACurso(id_curso) {
      this.$router.push({
        name: "Modulos",
        params: {
          id_curso: id_curso,
        },
      });
    },
    irAModulo(id_curso, id_modulo) {
      this.$router.push({
        name: "Modulos",
        params: { id_curso: id_curso, id_modulo: id_modulo },
      });
    },
    irALeccion(id_curso, id_modulo, id_leccion) {
      this.$router.push({
        name: "Leccion",
        params: {
          idcurso: id_curso,
          idmodulo: id_modulo,
          idleccion: id_leccion,
        },
      });
    },

    async obtenerCursoDeModulo(id_modulo) {
      const response = await ModuloService.getmoduloDetallado(id_modulo);
      const modulo = response.data.data || response.data;
      const id_curso = modulo.id_curso || modulo.id_curso;
      if (id_curso) {
        return id_curso;
      } else {
        return null;
      }
    },

    async obtenerCursoYModuloDeLeccion(id_leccion) {
      try {
        const response = await LeccionService.getLeccionDetallada(id_leccion);
        const data = response.data;
        const leccion = data.leccion || {};

        const id_modulo = leccion.id_modulo;
        const id_curso = leccion.modulo ? leccion.modulo.id_curso : null;

        if (id_curso && id_modulo) {
          return { id_curso, id_modulo };
        } else {
          console.error(
            "No se pudo obtener id_curso e id_modulo para la lección:",
            id_leccion
          );
          return {};
        }
      } catch (error) {
        console.error(
          "Error al obtener id_curso e id_modulo de la lección:",
          error
        );
        return {};
      }
    },

    //Notificaciones

    async obtenerNotificaciones() {
      const usuarioId = this.user.id;
      if (!usuarioId) {
        console.warn("ID del usuario no encontrado en localStorage.");
        return;
      }
      try {
        this.cargandoNotificaciones = true;
        const response =
          await notificacionesService.getNotificacionesPorUsuario(usuarioId);
        this.notificaciones = response.data.data.map((notificacion) => ({
          id: notificacion.id,
          tipo: notificacion.tipo,
          mensaje: notificacion.mensaje,
          fecha_envio: new Date(notificacion.fecha_envio).toLocaleString(
            "es-ES",
            {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }
          ),
          estado: notificacion.estado,
        }));
      } catch (error) {
        console.error("Error al cargar notificaciones:", error);
      } finally {
        this.cargandoNotificaciones = false;
      }
    },
    toggleMenu(e) {
      e.stopPropagation();
      this.showMenu = !this.showMenu;
      if (this.showMenu && this.notificaciones.length === 0) {
        this.obtenerNotificaciones();
      }
    },

    handleClickOutside(e) {
      const menu = this.$refs.notificationMenu;
      if (menu && !menu.contains(e.target)) {
        this.showMenu = false;
      }
    },

    closeMenu() {
      setTimeout(() => {
        this.showMenu = false;
      }, 100);
    },
    minimizeSidebar() {
      this.$store.commit("sidebarMinimize");
    },

    async irANotificacion(notificacion) {
      try {
        await this.desactivarNotificacion(notificacion.id);

        this.showMenu = false;

        switch (notificacion.tipo) {
          case "Cumpleaños":
            this.$router.push({ name: "Calendario" });
            break;
          case "Certificación":
            this.$router.push({ name: "Mis Certificados" });
            break;
          case "Bienvenida":
            this.$router.push({ name: "Mis Cursos" });
            break;
          default:
            console.warn(
              "Tipo de notificación desconocido:",
              notificacion.tipo
            );
        }
        this.obtenerNotificaciones();
      } catch (error) {
        console.error("Error al redirigir o desactivar notificación:", error);
      }
    },
    async desactivarNotificacion(notificacionId) {
      try {
        await notificacionesService.desactivar(notificacionId);
        const notificacion = this.notificaciones.find(
          (n) => n.id === notificacionId
        );
        if (notificacion) {
          notificacion.estado = 0;
        }
      } catch (error) {
        console.error("Error al desactivar notificación:", error);
      }
    },
  },
};
</script>

<style scoped>
.navbar-custom {
  background-color: white;
}

.notifications-menu {
  max-height: 400px; 
  overflow-y: auto;
  min-width: 300px;
  border-radius: 8px;
}

.dropdown-item-custom {
  padding: 10px;
  border-radius: 5px;
}


.notification-button {
  margin: 0 auto;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.suggestions-list li {
  padding: 8px 12px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

.bg-warning-custom {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0;
}

.perfil-foto-icono {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 250px;
  overflow-y: auto;
  z-index: 1000;
  padding: 8px 0;
  font-family: Arial, sans-serif;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  opacity: 1;
  transform: translateY(0);
}

.suggestions-list.hidden {
  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none;
}

.suggestions-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.suggestions-list li {
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #333;
  transition:
    background-color 0.2s ease,
    color 0.2s ease;
}

.suggestions-list li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.suggestions-list li:hover {
  background-color: #f7f9fc;
  color: #0056b3;
}

.suggestions-list li strong {
  color: #333;
  font-weight: bold;
}

.suggestions-list li span {
  font-size: 14px;
  color: #888;
}

.suggestions-list li:hover strong {
  color: #0056b3;
}

@media (max-width: 768px) {
  .bg-danger {
    margin-top: 18px;
    margin-left: -190px;
    background-color: red;
  }
}

</style>
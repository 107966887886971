<template>
  <div class="container-fluid">
    <h2 class="dtb-title"></h2>
    <div class="mt-4">
      <h3>Módulos</h3>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th class="d-none d-sm-table-cell text-center">Lecciones</th>
              <!--
              <th class="d-none d-sm-table-cell text-center">Calificaciones</th>
              <th class="d-none d-sm-table-cell text-center">Certificado</th>-->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="modulo in modulos"
              :key="modulo.id"
              style="background-color: white"
            >
              <td
                class="d-flex justify-content-between align-items-center py-2 modulo-td"
                @click="irLeccion(modulo.id, modulo.primera_leccion_id)"
                style="cursor: pointer;"
              >
                <span class="me-3">{{ modulo.nombre || "Sin nombre" }}</span>
                
              </td>
              <td class="d-none d-sm-table-cell text-center py-2">
                {{ modulo.cantidad_lecciones || "0" }}
              </td>
               <!--
              <td class="d-none d-sm-table-cell text-center py-2">
                {{ obtenerCalificacion(modulo.id) }}
              </td>
              <td class="d-none d-sm-table-cell text-center py-2">
                {{ obtenerCertificacion(modulo.id) }}
              </td>-->
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-show="hayExamen">
      <button
        class="btn boton_examen text-white m-2"
        @click="irAExamen"
        :disabled="!botonHabilitado"
      >
        Examen: {{ examen.nombre || "Sin título" }}
      </button>
      <div class="p-2">
        <span>
          Fecha de Apertura: {{ examen.fecha_apertura || "No disponible" }}
          <br />
          Fecha de Cierre: {{ examen.fecha_cierre || "No disponible" }}
        </span>
      </div>
    </div>

    <Preloader :visible="cargando" />
  </div>
</template>

<script>
import CalificacionService from "../../services/CalificacionService";
import CertificadoService from "../../services/CertificadoService";
import ModuloService from "../../services/ModuloService";
import ExamenService from "../../services/ExamenService";
import Preloader from "../components/Preloader.vue";

export default {
  name: "Modulo",
  data() {
    return {
      modulos: [],
      id_curso: this.$route.params.id_curso,
      calificaciones: {},
      certificados: {},
      user: JSON.parse(localStorage.getItem("user")),

      cargando: false,

      examen: {},
      hayExamen: false,
    };
  },
  components: {
    Preloader,
  },
  created() {
    this.fetchModulos(this.id_curso);
  },
  mounted() {
    this.fetchExamen();
  },
  computed: {
    botonHabilitado() {
      if (!this.examen.fecha_apertura || !this.examen.fecha_cierre) {
        return false;
      }

      const fechaActual = new Date();
      const fechaApertura = new Date(this.examen.fecha_apertura);
      const fechaCierre = new Date(this.examen.fecha_cierre);

      return fechaActual >= fechaApertura && fechaActual <= fechaCierre;
    },
  },
  methods: {
    async fetchExamen() {
      try {
        console.log("Fetching examen por curso...");
        this.cargando = true;
        const response = await ExamenService.getExamenPorCurso(this.id_curso);
        this.examen = response.data.data || {};
        this.hayExamen = Object.keys(this.examen).length > 0;
        console.log("Examen por curso encontrado:", this.hayExamen);
      } catch (error) {
        console.error("Error fetching Examen por curso:", error);
        this.hayExamen = false;
      } finally {
        this.cargando = false;
      }
    },
    async fetchModulos(id_curso) {
      try {
        this.cargando = true;
        const response = await ModuloService.getModulosporCurso(id_curso);
        this.modulos = response.data.data || [];
        for (const modulo of this.modulos) {
          this.llamarCalificacion(modulo.id);
          this.llamarCertificado(modulo.id);
        }
      } catch (error) {
        console.error("Error al cargar módulos:", error);
      } finally {
        this.cargando = false;
      }
    },
    async llamarCalificacion(id_modulo) {
      try {
        const response =
          await CalificacionService.getCalificacionporModulodeUsuario(
            id_modulo,
            this.user.id
          );
        this.calificaciones[id_modulo] =
          response.data.data[0]?.calificacion || "Sin calificación";
      } catch (error) {
        console.error("Error al cargar calificación:", error);
      }
    },
    async llamarCertificado(id_modulo) {
      try {
        const response =
          await CertificadoService.getCertificadoporModulodeUsuario(
            id_modulo,
            this.user.id
          );
        this.certificados[id_modulo] = response.data.data[0] || null;
      } catch (error) {
        console.error("Error al cargar certificado:", error);
      }
    },
    obtenerCalificacion(id_modulo) {
      return this.calificaciones[id_modulo] || "Sin calificación";
    },
    obtenerCertificacion(id_modulo) {
      return this.certificados[id_modulo] ? "Obtenido" : "Sin certificado";
    },
    irLeccion(id_m, id_l) {
      if (id_l) {
        this.$router.push({
          name: "Leccion",
          params: {
            idcurso: this.id_curso,
            idmodulo: id_m,
            idleccion: id_l,
          },
        });
      } else {
        this.$swal({
          icon: "warning",
          title: "Lección no disponible",
          text: "Este módulo no tiene lecciones disponibles.",
        });
      }
    },
    irAExamen() {
      this.$router.push({
        name: "Examen",
        params: {
          tipo: "curso",
          idmodulo: this.id_curso,
        },
      });
    },
  },
};
</script>
<style scoped>
.modulo-td {
  transition: background-color 0.3s ease; /* Suaviza la transición */
}

.modulo-td:hover {
  background-color: #f0f0f0; /* Cambia al color deseado */
}

</style>
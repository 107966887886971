<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-9">
        <div
          v-if="videoUrl"
          class="ratio ratio-16x9 mb-4 p-3 mt-4 mt-sm-4 mb-sm-5 video-container"
        >
          <iframe
            :src="videoUrl"
            title="Reproductor de video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <div class="mb-3">
          <h1 class="fs-4 text-primary">
            {{ leccion.titulo }}
          </h1>
          <p class="fs-6 text-muted">{{ nombre_modulo }}</p>
        </div>

        <div
          style="background-color: white; padding: 20px; border-radius: 20px"
        >
          <p><strong>Recursos</strong></p>
          <div class="card-body p-0">
            <div v-for="material in materiales" :key="material.id" class="">
              <template v-if="material.tipo == 'Enlace'">
                <hr />
                <a :href="material.texto" target="_blank" class="text-muted">
                  <i
                    class="fa fa-link me-sm-2 text-success"
                    aria-hidden="true"
                  ></i>
                  {{ material.nombre }}
                </a>
              </template>

              <template v-if="material.tipo == 'Archivo'">
                <hr />
                <button
                  @click="descargarArchivo(material.ruta)"
                  class="btn btn-primary d-flex align-items-center"
                >
                  <i class="fa fa-download me-2" aria-hidden="true"></i>
                  Descargar {{ material.nombre }}
                </button>
              </template>

              <template v-if="material.tipo == 'Texto'">
                <hr />
                <i
                  class="fa fa-file-text-o me-sm-2 text-warning"
                  aria-hidden="true"
                ></i>
                <div
                  v-html="material.texto"
                  style="
                    border: 1px solid black;
                    padding: 10px;
                    border-radius: 2px;
                  "
                ></div>
              </template>

              <template v-else-if="material.tipo == 'Otro'">
                <hr />
                <i
                  class="fa fa-file-o me-sm-2 text-primary"
                  aria-hidden="true"
                ></i>
                {{ material.nombre }}
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-3 barra-lateral my-3">
        <div class="lista-grupo">
          <h4 class="mb-3 titulo-modulo" style="color: #000b43">Contenido</h4>
          <p class="" style="color: #000b43">
            <strong>{{ nombre_modulo }}</strong>
          </p>
          <div class="linea-tiempo">
            <ul class="lista-grupo">
              <li
                v-for="(l, index) in lecciones"
                :key="index"
                class="lista-grupo-item lista-grupo-item-accion d-flex align-items-center"
                @click="irLeccion(l.id)"
              >
                <div class="circulo">{{ index + 1 }}</div>
                <span class="ms-2">{{ l.titulo }}</span>
              </li>
            </ul>
            <div v-show="hayExamen">
              <button
                class="btn boton_examen text-white m-2"
                @click="irAExamen()"
                :disabled="!botonHabilitado"
              >
                Examen : {{ examen.nombre }}
              </button>
              <div class="p-2">
                <span>
                  Fecha de Apertura: {{ examen.fecha_apertura }} <br />
                  Fecha de Cierre: {{ examen.fecha_cierre }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Preloader :visible="cargando" />
  </div>
</template>

<script>
import LeccionService from "../../services/LeccionService";
import ArchivoService from "../../services/ArchivoService";
import MaterialService from "../../services/MaterialService";
import ComentarioService from "../../services/ComentarioService";
import Preloader from "../components/Preloader.vue";
import ExamenService from "../../services/ExamenService";
const YOUTUBE_BASE_URL = "https://www.youtube.com/embed/";
const VIMEO_BASE_URL = "https://player.vimeo.com/video/";

export default {
  name: "Leccion",
  data() {
    return {
      id_leccion: {},
      leccion: {},
      lecciones: {},
      archivos: [],
      materiales: [],
      sesiones: [],
      comentarios: [],
      nombre_modulo: "",

      idcurso: this.$route.params.idcurso,
      idmodulo: this.$route.params.idmodulo,
      idleccion: this.$route.params.idleccion,
      cargando: false,

      examen: {},
      hayExamen: false,
    };
  },
  components: {
    Preloader,
  },
  created() {
    this.fetchLeccion(this.idleccion);
    this.fecthLeccionesporModulo(this.idmodulo);
  },
  mounted() {
    this.fetchExamen();
  },
  computed: {
    videoUrl() {
      if (!this.archivos || !this.archivos.enlace) {
        return null;
      }

      const videoId = this.archivos.enlace;
      let baseUrl = "";

      if (
        this.archivos.tipo_video &&
        this.archivos.tipo_video.toLowerCase() === "youtube"
      ) {
        baseUrl = YOUTUBE_BASE_URL;
      } else if (
        this.archivos.tipo_video &&
        this.archivos.tipo_video.toLowerCase() === "vimeo"
      ) {
        baseUrl = VIMEO_BASE_URL;
      }

      return baseUrl && videoId ? baseUrl + videoId : null;
    },
    botonHabilitado() {
      if (!this.examen.fecha_apertura || !this.examen.fecha_cierre) {
        return false;
      }

      const fechaActual = new Date();
      const fechaApertura = new Date(this.examen.fecha_apertura);
      const fechaCierre = new Date(this.examen.fecha_cierre);

      return fechaActual >= fechaApertura && fechaActual <= fechaCierre;
    },
  },

  methods: {
    rutaArchivo(ruta) {
      if (!ruta) return "";
      console.log("ruta", process.env.VUE_APP_API_URL + "/storage/" + ruta);

      return process.env.VUE_APP_API_URL + "/storage/" + ruta;
    },

    async descargarArchivo(ruta) {
      const url = this.rutaArchivo(ruta);
      window.open(url, "_blank");
    },

    async fetchLeccion(id_leccion) {
      try {
        this.cargando = true;
        const response = await LeccionService.getLeccionDetallada(id_leccion);
        this.leccion = response.data.leccion;
        // this.fetchExamen();
        this.nombre_modulo = response.data.leccion.modulo.nombre;
        console.log("mod : " + this.nombre_modulo);

        this.materiales = response.data.materiales;
        this.sesiones = response.data.sesiones;
        this.archivos = response.data.archivos[0];
      } catch (error) {
        console.error("Error fetching Cursos:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: error,
        });
      } finally {
        this.cargando = false;
      }
    },
    async fetchExamen() {
      try {
        console.log("Fetching examen...");
        this.cargando = true;
        const response = await ExamenService.getExamenPorModulo(this.idmodulo);
        this.examen = response.data.data;
        this.hayExamen = this.examen && Object.keys(this.examen).length > 0;
        console.log("Examen encontrado:", this.hayExamen);
      } catch (error) {
        console.error("Error fetching Examen:", error);
        this.hayExamen = false;
      } finally {
        this.cargando = false;
      }
    },

    async fecthLeccionesporModulo(id_modulo) {
      try {
        this.cargando = true;
        const response = await LeccionService.getLeccionesPorModulo(id_modulo);
        this.lecciones = response.data.data;
      } catch (error) {
        console.error("Error fetching Cursos:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los Cursos.",
        });
      } finally {
        this.cargando = false;
      }
    },
    async fetchArchivo(id) {
      try {
        this.cargando = true;
        const response = await ArchivoService.getArchivoPorLeccion(id);
        this.archivo = response.data.data;
        this.fechMaterial(id);
        this.fechComentario(id);
      } catch (error) {
        console.error("Error fetching Cursos:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los Cursos.",
        });
      } finally {
        this.cargando = false;
      }
    },
    async fetchMaterial(id) {
      try {
        this.cargando = true;
        const response = await MaterialService.getMaterialporLeccion(id);
        this.material = response.data.data;
      } catch (error) {
        console.error("Error fetching Cursos:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los Cursos.",
        });
      } finally {
        this.cargando = false;
      }
    },
    async fechComentario(id) {
      try {
        this.cargando = true;
        const response = await ComentarioService.getComentarioPorLeccion(id);
        this.comentarios = response.data.data;
      } catch (error) {
        console.error("Error fetching Cursos:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los Cursos.",
        });
      } finally {
        this.cargando = false;
      }
    },
    irLeccion(idleccion) {
      this.fetchLeccion(idleccion);
      this.$router.push({
        name: "Leccion",
        params: {
          idleccion: idleccion,
          idmodulo: this.idmodulo,
          idcurso: this.idcurso,
        },
      });
    },
    irAExamen() {
      this.$router.push({
        name: "Examen",
        params: {
          tipo: "modulo",
          idtipo: this.idmodulo,
        },
      });
    },
  },
};
</script>

<style>
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.barra-lateral {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 20px;
}

.linea-tiempo {
  border-left: 2px solid #0056b3;
  padding-left: 0px;
  position: relative;
}

.lista-grupo {
  list-style-type: none;
  padding: 0;
}

.lista-grupo-item {
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.lista-grupo-item:active {
  background-color: blue;
}

.lista-grupo-item:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

.circulo {
  width: 20px;
  height: 20px;
  background-color: #f97c00;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}
.circulo2 {
  width: 20px;
  height: 20px;
  background-color: #f97c00;
  border-radius: 50%;
  justify-content: center;
  color: white;
  font-weight: bold;
}

.titulo-modulo {
  color: #0056b3;
  font-size: 1.5rem;
}

.titulo-modulo-pequeño {
  font-size: 1.2rem;
  color: #6c757d;
  margin-bottom: 10px;
}

.boton_examen {
  background-color: #000b43 !important;
}

.video-container {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
</style>

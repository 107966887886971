<template>
  <div class="container mt-4">
    <div class="card">
      <div class="card-body">
        <!-- Sección de imagen y botón para cambiar foto -->
        <div class="d-flex align-items-center mb-4 position-relative">
          <!-- Mostrar imagen de perfil o placeholder -->
          <div
            v-if="!usuario.foto_perfil && !previewImage"
            class="bg-warning rounded-circle me-3"
            style="width: 80px; height: 80px"
          ></div>
          <img
            v-else
            :src="previewImage || usuario.foto_perfil"
            class="rounded-circle me-3"
            style="width: 80px; height: 80px; object-fit: cover"
          />
          <!-- Información del usuario -->
          <div>
            <h4 class="mb-0">{{ usuario.nombres }} {{ usuario.apellidos }}</h4>
            <p class="text-muted mb-0">{{ usuario.profesion }}</p>
          </div>
        </div>

        <!-- Botón para cambiar foto de perfil debajo de la imagen -->
        <div class="mb-4">
          <button class="btn btn-primary" @click="activarCambioImagen">
            Cambiar Foto de Perfil
          </button>
          <input
            type="file"
            ref="inputImagen"
            class="d-none"
            @change="handleFileUpload"
            accept="image/*"
          />
        </div>

        <!-- Formulario de actualización de perfil -->
        <form @submit.prevent="actualizarPerfil">
          <div class="row g-3">
            <!-- Nombres (Deshabilitado) -->
            <div class="col-md-4">
              <label for="nombres">Nombres</label>
              <input
                id="nombres"
                type="text"
                class="form-control"
                placeholder="Nombres"
                v-model="usuario.nombres"
                disabled
              />
            </div>
            <!-- Apellidos (Deshabilitado) -->
            <div class="col-md-4">
              <label for="apellidos">Apellidos</label>
              <input
                id="apellidos"
                type="text"
                class="form-control"
                placeholder="Apellidos"
                v-model="usuario.apellidos"
                disabled
              />
            </div>
            <!-- Correo (Deshabilitado) -->
            <div class="col-md-4">
              <label for="correo">Correo</label>
              <input
                id="correo"
                type="email"
                class="form-control"
                placeholder="Correo"
                v-model="usuario.correo"
                disabled
              />
            </div>
            <!-- Teléfono -->
            <div class="col-md-4">
              <label for="telefono">Teléfono</label>
              <input
                id="telefono"
                type="tel"
                class="form-control"
                placeholder="Teléfono"
                v-model="usuario.telefono"
              />
            </div>
            <!-- Dirección -->
            <div class="col-md-4">
              <label for="direccion">Dirección</label>
              <input
                id="direccion"
                type="text"
                class="form-control"
                placeholder="Dirección"
                v-model="usuario.direccion"
              />
            </div>
            <!-- Ciudad -->
            <div class="col-md-4">
              <label for="ciudad">Ciudad</label>
              <input
                id="ciudad"
                type="text"
                class="form-control"
                placeholder="Ciudad"
                v-model="usuario.ciudad"
              />
            </div>
            <!-- Profesión -->
            <div class="col-md-4">
              <label for="profesion">Profesión</label>
              <input
                id="profesion"
                type="text"
                class="form-control"
                placeholder="Profesión"
                v-model="usuario.profesion"
              />
            </div>
            <!-- Número de Documento (Deshabilitado) -->
            <div class="col-md-4">
              <label for="numero_documento">Número de Documento</label>
              <input
                id="numero_documento"
                type="text"
                class="form-control"
                placeholder="Número de Documento"
                v-model="usuario.numero_documento"
                disabled
              />
            </div>
            <!-- Tipo de Documento (Deshabilitado) -->
            <div class="col-md-4">
              <label for="tipo_documento">Tipo de Documento</label>
              <input
                id="tipo_documento"
                type="text"
                class="form-control"
                placeholder="Tipo de Documento"
                v-model="usuario.tipo_documento"
                disabled
              />
            </div>
            <!-- Fecha de Nacimiento -->
            <div class="col-md-4">
              <label for="fecha_nacimiento">Fecha de Nacimiento</label>
              <input
                id="fecha_nacimiento"
                type="date"
                class="form-control"
                v-model="usuario.fecha_nacimiento"
              />
            </div>
            <!-- Nueva Contraseña -->
            <div class="col-md-4">
              <label for="password">Nueva Contraseña</label>
              <input
                id="password"
                type="password"
                class="form-control"
                placeholder="Nueva Contraseña"
                v-model="usuario.password_hash"
              />
            </div>
            <!-- Repetir Nueva Contraseña -->
            <div class="col-md-4">
              <label for="repetir_password">Repetir Nueva Contraseña</label>
              <input
                id="repetir_password"
                type="password"
                class="form-control"
                placeholder="Repetir Nueva Contraseña"
                v-model="repetirContrasena"
              />
            </div>
          </div>

          <!-- Botones de acción -->
          <div class="mt-4">
            <button type="submit" class="btn btn-danger me-2">
              Actualizar
            </button>
            <button
              type="button"
              class="btn btn-link text-dark"
              @click="cancelarEdicion"
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UsuarioService from "@/services/UsuarioService";

export default {
  name: "ProfileForm",
  data() {
    return {
      usuario: {
        nombres: "",
        apellidos: "",
        correo: "",
        telefono: "",
        direccion: "",
        ciudad: "",
        profesion: "",
        password_hash: "",
        numero_documento: "",
        foto_perfil: null,
        tipo_cliente: "",
        tipo_documento: "",
        fecha_nacimiento: "",
      },
      file: null,
      repetirContrasena: "",
      previewImage: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  created() {
    this.obtenerUsuario();
  },
  methods: {
    async obtenerUsuario() {
      try {
        const response = await UsuarioService.getPerfilUsuario(this.user.id);
        this.usuario = response.data.data;
        if (this.usuario.foto_perfil) {
          this.usuario.foto_perfil =
            process.env.VUE_APP_API_URL + this.usuario.foto_perfil;
        }
        this.previewImage = this.usuario.foto_perfil;
        this.usuario.password_hash = "";
      } catch (error) {
        console.error("Error al obtener el perfil del usuario:", error);
      }
    },
    cancelarEdicion() {
      this.$router.push('/'); 
      this.obtenerUsuario();
      this.previewImage = this.usuario.foto_perfil;
      this.file = null;
      this.usuario.password_hash = "";
      this.repetirContrasena = "";
    },
    activarCambioImagen() {
      this.$refs.inputImagen.click();
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(this.file);
      } else {
        this.previewImage = null;
      }
    },
    async actualizarPerfil() {
      if (this.usuario.password_hash !== this.repetirContrasena) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Las contraseñas no coinciden",
        });
        return;
      }
      const formData = new FormData();
      formData.append("nombres", this.usuario.nombres || "");
      formData.append("apellidos", this.usuario.apellidos || "");
      formData.append("correo", this.usuario.correo || "");
      formData.append("numero_documento", this.usuario.numero_documento || "");
      formData.append("telefono", this.usuario.telefono || "");
      formData.append("direccion", this.usuario.direccion || "");
      formData.append("tipo_cliente", "Alumno");
      formData.append("estado", 1);
      formData.append("password", this.usuario.password_hash || "");
      formData.append("password_confirmation", this.repetirContrasena || "");
      formData.append("ciudad", this.usuario.ciudad || "");
      formData.append("profesion", this.usuario.profesion || "");
      formData.append("tipo_documento", this.usuario.tipo_documento || "");
      formData.append("fecha_nacimiento", this.usuario.fecha_nacimiento || "");

      if (this.file) {
        formData.append("foto_perfil", this.file);
      }
      try {
        formData.append("_method", "PUT");
        const response = await UsuarioService.updateUsuario(
          this.user.id,
          formData
        );
        this.usuario = response.data.data;

        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(this.usuario));

        this.$store.commit("setUser", this.usuario);

        this.$swal({
          icon: "success",
          title: "Usuario Actualizado",
          text: "El usuario ha sido actualizado exitosamente.",
        });
        location.reload();
      } catch (error) {
        console.error("Error al actualizar el perfil:", error);
        const mensaje =
          error.response?.data?.mensaje || "Ocurrió un error inesperado.";
        this.$swal(mensaje, "", "error");
      }
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  margin: 0 auto; 
}

.card-body {
  padding: 1.5rem; 
}

.d-flex {
  margin-bottom: 1rem; 
}

.d-flex .me-3 {
  margin-right: 1rem; 
}

h4.mb-0 {
  font-size: 1.25rem;
}

.text-muted.mb-0 {
  font-size: 0.875rem;
}

.mb-4 {
  margin-bottom: 1.5rem; 
}

.form-label {
  font-size: 0.9rem; 
}

.form-control,
.form-select {
  padding: 0.75rem 1rem;
  border-radius: 6px;
}

.row.g-3 {
  margin-right: 0; 
  margin-left: 0;  
}

.col-md-4 {
  margin-bottom: 1rem; 
}

.btn {
  padding: 0.5rem 1.5rem; 
}

.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
}

.btn-danger {
  background-color: #ff4858;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 6px;
}

.btn-link {
  text-decoration: none;
}
</style>
